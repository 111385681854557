import { fetchGet, fetchPost } from './index';

//#region 8737 10-Prepayment Qualified
export const unQualifiedDealerReportList = (params = {}): Promise<any> => {
    return fetchPost(
        '/ptapi/dealer/report/unQualifiedDealerReportList',
        params
    );
};
export const qualifiedDealerReportList = (params = {}): Promise<any> => {
    return fetchPost('/ptapi/dealer/report/QualifiedDealerReportList', params);
};
//#endregion

//#region 8727 2-RV Submission
export const querySubmission = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/report/query/submission', { params });
};
//#endregion

//SaleOverview
export const getPrepaymentUnQualifiedDealer = (params = {}): Promise<any> => {
    return fetchGet('/ptapi/dealer/report/PrepaymentUnQualifiedDealer', {
        params,
    });
};
export const getPrepaymentQualifiedDealer = (params = {}): Promise<any> => {
    return fetchGet('/ptapi/dealer/report/PrepaymentQualifiedDealer', {
        params,
    });
};
export const getIncentive = (params = {}): Promise<any> => {
    return fetchPost('/rpapi/sales/panel/overview/of/incentive', params);
};
export const getIncentiveAll = (params = {}): Promise<any> => {
    return fetchPost('/rpapi/sales/panel/overview/of/incentive/all', params);
};
//获取车辆Brand

export const findBrandByBu = (params = {}): Promise<any> => {
    return fetchGet('/vapi/noAuth/findBrandByBu', { params });
};

export const getRVOverview = (): Promise<any> => {
    return fetchGet('/rvapi/report/query/overview');
};
export const getQuarter = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/report/query/quarter', { params });
};
export const getRVTable = (params = {}, url: string): Promise<any> => {
    return fetchGet('/rvapi' + url, { params });
};
export const getTDTA = (
    params = { year: '', quarter: 0, dealerCode: '', bu: '' }
): Promise<any> => {
    return fetchPost(
        `/claimapi/systemCalculation/tdta/getTdtaReportData?year=${params.year}&quarter=${params.quarter}&dealerCode=${params.dealerCode}&bu=${params.bu}`
    );
};

//#SaleOverview

//#failedReasonAnalysisDealer
export const getFailedReasonAnalysis = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/failedReasonAnalysis/search', params);
};
//#failedReasonAnalysisDealer

//#payoutForm
export const getPayoutRoundList = (data = {}, year: string): Promise<any> => {
    return fetchPost(
        // '/ptapi/payoutForm/report/payoutRoundList?year=' + year,
        '/rpapi/payoutForm/report/payoutRoundList?year=' + year,
        data
    );
};
export const getBrandByBuID = (params = {}): Promise<any> => {
    return fetchGet('/vapi/noAuth/findBrandByBu', { params });
};
export const getPayoutFormByModelList = (params = {}): Promise<any> => {
    return fetchPost('/rpapi/payoutForm/report/payoutFormByModelList', params);
    // return fetchPost('/ptapi/payoutForm/report/payoutFormByModelList', params);
};

//payoutForm

//#通用接口，获取region和subregion
export const getRetrieveRegionList = (id: string): Promise<any> => {
    return fetchPost('/claimapi/common/retrieveRegionList?buId=' + id);
};
export const getRetrieveSubRegionList = (id: string): Promise<any> => {
    return fetchPost('/claimapi/common/retrieveSubRegionList?buId=' + id);
};

// 14443 checking-estimation-controller
export const checkingEstimation = (params = {}): Promise<any> => {
    // return fetchPost('/ptapi/checking/estimation/list', params);
    return fetchPost('/rpapi/checking/estimation/list', params);
};

// RV 柱状图数据获取
export const queryHistogram = (params = {}): Promise<any> => {
    return fetchGet('/rvapi/report/query/histogram', { params });
};

// 报表2-5 Checker Management
export const getCheckerManagement = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/checker/management/page', params);
};

//报表2-2 获取表格数据
export const getPenetrationTable = (params = {}): Promise<any> => {
    return fetchPost('/rpapi/penetration/report/page', params);
};

//根据dealerGroup id获取 dealer数组信息
export const getDealerByGroupId = (id: string): Promise<any> => {
    return fetchGet(
        `/dapi/noAuth/dealer/byDealerGroup/{dealerGroupId}?dealerGroupId=${id}`
    );
};

//根据bu获取dealercode信息
//! sicco sipo只能通过 bu来找到相关的 dealer的信息
export const getDealerByBUID = (params = {}): Promise<any> => {
    return fetchGet(`/dapi/noAuth/dealer/dealerData`, { params });
};

export const getTDTA2 = (params = {}): Promise<any> => {
    // return fetchPost(
    //     `/claimapi/systemCalculation/tdta/getTdtaReportData?year=${(params as any).year}&quarter=${(params as any).quarter}&dealerCode=${(params as any).dealerCode}&bu=${(params as any).bu}`
    // );
    return fetchPost(
        `/claimapi/systemCalculation/tdta/getTdtaReportData`,
        params
    );
};

// 1-10
export const getAllDealerInfos = (): Promise<any> => {
    return fetchGet(`/ptapi/dealer/report/allDealerInfos`);
};

// 2-1
export const getSalesIncentiveOverview = (params: {}): Promise<any> => {
    return fetchGet(`/claimapi/sales/panel/sales/incentive/overview`, {
        params,
    });
};

//* 2-9 报表接口
export const getCheckingOverviewList = (params = {}): Promise<any> => {
    return fetchPost(`/rpapi/paymentTrackingFC/trackingOverview/list`, params);
};

export const getCheckingOverviewReleaseList = (params = {}): Promise<any> => {
    return fetchPost(
        `/rpapi/paymentTrackingFC/trackingOverview/release/list`,
        params
    );
};

export const getCheckingOverviewReleaseSave = (params = {}): Promise<any> => {
    return fetchPost(
        `/rpapi/paymentTrackingFC/trackingOverview/release/save`,
        params
    );
};

export const getProgramTrackingDetailList = (params = {}): Promise<any> => {
    return fetchPost(
        `/rpapi/paymentTrackingFC/programTrackingDetail/list`,
        params
    );
};

export const getProgramTrackingDetailSave = (params = {}): Promise<any> => {
    return fetchPost(
        `/rpapi/paymentTrackingFC/programTrackingDetail/save`,
        params
    );
};

//报表2-2 查询下拉选项PDI
export const getSSSC = (params = {}): Promise<any> => {
    return fetchGet(`/pgapi/program/data/program/search_sssc_list`,{params});
};
//报表2-2 查询下拉选项SSSC
export const getPDI = (params = {}): Promise<any> => {
    return fetchGet(`/pgapi/program/data/program/search_pdi_list`,{params});
};

//报表2-2 查询下拉选项Option Package
export const getOptionPackage = (params = {}): Promise<any> => {
    return fetchPost(
        `/rpapi/penetration/report/option/package/drop`,
        params
    );
};

